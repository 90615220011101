module.exports = {
  first: 'first',
  firstA: {
    DisplaySettings: 'Settings',
    monitorDeviceTotal: 'Monitor Device Total',
    Unclosedhazards: 'Unclosed hazards',
    UnhandleAlarms: 'Unhandled alarm',
    offlineDevice: 'Offline device',
    Numberofalarmdevicestoday: 'Number of alarm device stoday',
    alarmprocessinginthepast7days: 'Alarm processing in the past 7days',
    alarmStatistics: 'alarm Statistics',
    deviceState: 'device State',
    Alarmlevelproportion: 'Alarm level proportion',
    AlarmTypeProportion: 'Alarm type proportion',
    Alarmprocessingproportion: 'Alarm processing proportion',
    device: 'device',
    time: 'time',
    alarmLevel: 'alarmLevel',
    alarmType: 'alarmType',
    state: 'state'
  },
  alarm: 'alarm',
  alarmA: {
    name: 'name',
    time: 'time',
    alarmLevel: 'Alarmlevel',
    alarmType: 'Alarmtype',
    alarmContent: 'Alarmcontent',
    alarmDetail: 'Alarmdetail',
    handle: 'Handle',
    Operate: 'Operate',
    query: 'query',
    export: 'export',
    batchHandel: 'Batchhandel',
    detail: 'detail',
    alarmHistory: 'Alarmhistory',
    unhandle: 'unhandle',
    processed: 'processed'
  },
  monitor: 'monitor',
  monitorA: {
    tunnel: 'tunnel',
    tunnelA: {
      OpticalFiberThermometry: 'Optical fiber thermometry',
      OpticalFiberThermometryA: {
        Channel: 'Channel',
        Datatime: 'Data time',
        Maximumtemperature: 'Maximum temperature',
        Minimumtemperature: 'Minimum temperature',
        Averagetemperature: 'Average temperature',
        Carryingcapacity: 'Carrying capacity',
        Fiberbreakingposition: 'Fiber breaking position',
        Numberofalarmpoints: 'Number of alarm points',
        Maximumtemperaturealarm: 'Maximum temperature alarm',
        Currentcarryingalarm: 'Current-carrying alarm',
        Operate: 'Operate',
        tunnel: 'tunnel',
        line: 'line',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        partition: 'partition',
        Fireproofpartition: 'Fireproof partition',
        fan: 'fan',
        Waterpump: 'Waterpump',
        state: 'state',
        control: 'control',
        Callingfortesting: 'Callingfortesting',
        loadingData: 'Obtaining data, please be patient and wait...'
      },
      CableLocalDischarge: 'Cable local discharge',
      CableLocalDischargeA: {
        Name: 'Name',
        Datatime: 'Data time',
        AorBorCPeakdischarge: 'A/B/C Peakdischarge',
        AorBorCCumulativedischarge: 'A/B/C Cumulativedischarge',
        AorBorCDischargefrequency: 'A/B/C Dischargefrequency',
        AorBorCAlarmlevel: 'A/B/C Alarmlevel',
        Operate: 'Operate'
      },
      SheathCurrent: 'Sheath current',
      SheathCurrentA: {
        Name: 'Name',
        Datatime: 'Data time',
        Aphasecurrent: 'A phase current',
        Bphasecurrent: 'B phase current',
        Cphasecurrent: 'C phase current',
        Aphasejointtemperature: 'A-phase joint temperature',
        Bphasejointtemperature: 'B-phase joint temperature',
        Cphasejointtemperature: 'C-phase joint temperature',
        tempN: 'tempN',
        Runningcurrent: 'Running current',
        TotalgroundingCurrent: 'TotalgroundingCurrent',
        Extremalratio: 'Extremal ratio',
        Loadratio: 'Load ratio',
        CurrentTB: 'Current of Phase B',
        CurrentTC: 'Current of Phase C',
        Operate: 'Operate'
      },
      EnvironmentalMonitoring: 'Environmental monitoring',
      EnvironmentalMonitoringA: {
        Name: 'Name',
        Datatime: 'Data time',
        Otwoconcentration: 'O₂ concentration',
        CHfourconcentration: 'CH₄ concentration',
        HtwoSconcentration: 'H₂S concentration',
        COconcentration: 'CO concentration',
        Temperature: 'Temperature',
        Humidness: 'Humidness',
        Operate: 'Operate'
      },
      WaterLevelMonitoring: 'Water level monitoring',
      WaterLevelMonitoringA: {
        Name: 'Name',
        Datatime: 'Data time',
        Waterlevel: 'Water level',
        Operate: 'Operate'
      },
      ManholeCoverMonitoring: 'Manhole cover monitoring',
      ManholeCoverMonitoringA: {
        Name: 'Name',
        Datatime: 'Data time',
        Eventtype: 'Event type',
        Openandclosedstate: 'Open and closed state',
        Intrusionstate: 'Intrusion state',
        Inclinedcondition: 'Inclined condition',
        Vibrationalstate: 'Vibrational state',
        Damagedcondition: 'Damaged condition',
        Operate: 'Operate'
      }
    },
    Substation: 'Substation',
    SubstationA: {
      SubstationGroundGrid: 'Substation ground grid',
      SubstationGroundGridA: {
        query: 'query',
        add: 'add',
        detectionunit: 'Detection unit',
        StartTime: 'Start time',
        EndTime: 'End time',
        Approver: 'Approver',
        Checker: 'Checker',
        Surveyor: 'Surveyor',
        DrawingNo: 'Drawing no',
        version: 'version',
        Operate: 'Operate',
        enter: 'enter',
        modify: 'modify',
        detail: 'detail',
        delete: 'delete',
        StartingcoordinatesX: 'Starting coordinates x',
        StartingcoordinatesY: 'Starting coordinates y',
        TerminationcoordinatesX: 'Termination coordinates x',
        TerminationcoordinatesY: 'Termination coordinates y',
        fieldstrength: 'fieldstrength',
        operate: 'operate'
      }
    },
    overflow: 'overflow',
    overflowA: {
      treeId: 'treeName',
      timeStamp: 'timeStamp',
      tempAir1: 'tempAir1(℃)',
      humidity1: 'humidity1(%RH)',
      tempAir2: 'tempAir2(℃)',
      humidity2: 'humidity2(%RH)',
      voltageOut: 'voltageOut(V)',
      currentOut: 'currentOut(A)',
      voltageAc: 'voltageAc(V)',
      tempPfc: 'tempPfc(℃)',
      voltageOutDc: 'voltageOutDc(V)',
      currentLoad: 'currentLoad(A)',
      currentBat: 'currentBat(A)',
      tempBat: 'tempBat(℃)',
      voltageBat: 'voltageBat(V)',
      powerSupply: 'powerSupply',
      alarmDesc: 'alarmDesc',
      Operate: 'Operate'
    },
    Visualization: 'Visualization',
    VisualizationA: {
      RealVideo: 'Real video',
      InspectionRobot: 'Inspection robot',
      threeDSecurityLaser: 'threeD security laser',
      threeDSecurityPointCloud: 'threeD security pointCloud',
      InspectionRobotA: {
        batchOperation: 'Batch operation',
        query: 'Query',
        batchWakeUp: 'Batch WakeUp',
        batchSleep: 'Batch Sleep',
        wakeUp: 'WakeUp',
        sleep: 'Sleep',
        code: 'Code',
        name: 'Name',
        issued: 'Issued',
        response: 'Response',
        Operate: 'Operate',
        loadingData: 'Obtaining data, please be patient and wait...',
        total: 'Total',
        number: 'Robot'
      }
    },
    TransmitElectricity: 'Transmit electricity',
    TransmitElectricityA: {
      TowerTilt: 'Tower tilt',
      TowerTiltA: {
        name: 'name',
        datetime: 'date time',
        inclination: 'inclination',
        Inclinationalongline: 'Inclinationa long line',
        Lateralinclination: 'Lateral inclination',
        Alonglineinclinationangle: 'Along line inclination angle',
        Lateralinclinationangle: 'Lateral inclination angle',
        Eastwarddisplacement: 'Eastward displacement',
        Northbounddisplacement: 'Northbound displacement',
        verticaldisplacement: 'vertical displacement',
        operate: 'operate',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        allExport: 'allExport',
        Callingfortesting: 'Calling for testing',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        data: 'data'
      },
      Transmissionmeteorology: 'Transmission meteorology',
      TransmissionmeteorologyA: {
        name: 'name',
        dataTime: 'Data time',
        averagewindspeed: 'Average windspeed',
        Averagewinddirection: 'Average winddirection',
        maximumwindspeed: 'Maximum windspeed',
        extremewindspeed: 'Extreme windspeed',
        Standardizedwindspeed: 'Standardized windspeed',
        airtemperature: 'Air temperature',
        humidity: 'humidity',
        pressure: 'pressure',
        rainfall: 'rainfall',
        Precipitationintensity: 'Precipitation intensity',
        Lightradiationintensity: 'Light radiation intensity',
        operate: 'operate',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        Callingfortesting: 'Calling for testing',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        data: 'data',
        allexport: 'allExport'
      },
      WireTemperatureMeasurement: 'Wire temperatureMeasurement',
      WireTemperatureMeasurementA: {
        name: 'name',
        DataStart: 'Data start',
        DataEnd: 'Data end',
        gathertotal: 'Gather total',
        maximumtemperature: 'maximum temperature',
        minimumtemperature: 'minimum temperature',
        Numberofcollected0values: 'Number of collected 0 values',
        Collectionofcollectionpoints: 'Collection of collection points',
        operate: 'operate',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        Callingfortesting: 'Calling for testing',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        data: 'data',
        allexport: 'allExport'
      },
      TowerStress: 'TowerStress',
      TowerStressA: {
        name: 'name',
        dataTime: 'Data time',
        gathertotal: 'Gather total',
        Maximumvalue: 'Maximum value',
        minimumvalue: 'Minimum value',
        averagevalue: 'Average value',
        Collectionofcollectionpoints: 'Collection of collection points',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        allExport: 'allExport',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        data: 'data'
      },
      BoltTighteningForce: 'BoltTighteningForce',
      BoltTighteningForceA: {
        name: 'name',
        dataTime: 'Data time',
        gathertotal: 'Gather total',
        Maximumvalue: 'Maximum value',
        minimumvalue: 'minimum value',
        averagevalue: 'Average value',
        Collectionofcollectionpoints: 'Collection of collection points',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        allExport: 'allExport',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        data: 'data'
      },
      WireDance: 'WireDance',
      WireDanceA: {
        name: 'name',
        dataTime: 'Data time',
        Totalnumberofcollectionunits: 'Total number of collection units',
        Collectionunitserialnumber: 'Collection unit serial number',
        Danceamplitude: 'Dance amplitude',
        Verticaldanceamplitude: 'Vertical dance amplitude',
        Horizontaldanceamplitude: 'Horizontal dance amplitude',
        Dancingellipseinclinationangle: 'Dancing ellipse inclination angle',
        Dancefrequency: 'Dance frequency',
        operate: 'operate',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        loadingData: 'Obtaining data, please be patient and wait...',
        count: 'count',
        Callingfortesting: 'Calling for testing',
        Featurequantityexport: 'Feature quantity export',
        Trackexport: 'Track export',
        feature: 'feature',
        trajectory: 'trajectory',
        offset: 'offset'
      },
      IcingTension: 'IcingTension',
      IcingTensionA: {
        name: 'name',
        dataTime: 'Data time',
        Icethickness: 'Ice thickness',
        Suspendedload: 'Suspended load',
        tensiondifference: 'tension difference',
        Numberofsensors: 'Number of sensors',
        Tensilevalue: 'Tensile value',
        Winddeflectionangle: 'Wind deflection angle',
        offsetangle: 'offset angle',
        dataset: 'data set',
        operate: 'operate',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        allExport: 'allExport',
        loadingData: 'Obtaining data, please be patient and wait...',
        Callingfortesting: 'Calling for testing',
        count: 'count',
        data: 'data'
      },
      GroundingElectrodeMonitoring: 'GroundingElectrodeMonitoring',
      GroundingElectrodeMonitoringA: {
        name: 'name',
        dataTime: 'Data time',
        Groundingresistance: 'Grounding resistance',
        batteryvoltage: 'Battery voltage',
        Signalstrength: 'Signal strength',
        Numberoflightningstrikes: 'Number of lightning strikes',
        Recentlightningstriketime: 'Recent lightning strike time',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        export: 'export',
        loadingData: 'Obtaining data, please be patient and wait...',
        Signalstrength1: 'Signal strength 1',
        Signalstrength2: 'Signal strength 2',
        LeakagecurrentA: 'Leakagecurrent A',
        LeakagecurrentB: 'Leakagecurrent B',
        LeakagecurrentC: 'Leakagecurrent C',
        NumberoflightningstrikesNo: 'No of lightning strikes',
        NumberoflightningstrikesA: 'Number of lightning strikes A',
        NumberoflightningstrikesB: 'Number of lightning strikes B',
        NumberoflightningstrikesC: 'Number of lightning strikes C',
        towergroundingresistance: 'tower grounding resistance',
        Towergroundingcurrent: 'Tower grounding current',
        Apolegroundingresistance: 'A-pole grounding resistance',
        Bpolegroundingresistance: 'B-pole grounding resistance',
        Cpolegroundingresistance: 'C-pole grounding resistance',
        Dpolegroundingresistance: 'D-pole grounding resistance',
        Apolegroundingcurrent: 'A-pole grounding current',
        Bpolegroundingcurrent: 'B-pole grounding current',
        Cpolegroundingcurrent: 'C-pole grounding current',
        Dpolegroundingcurrent: 'D-pole grounding current',
        Apolegroundingimpulsecurrent: 'A-pole grounding impulse current',
        Bpolegroundingimpulsecurrent: 'B-pole grounding impulse current',
        Cpolegroundingimpulsecurrent: 'C-pole grounding impulse current',
        Dpolegroundingimpulsecurrent: 'D-pole grounding impulse current',
        allExport: 'allExport',
        count: 'count',
        data: 'data',
        temp: 'Lightning strike statistics'
      },
      WireShift: 'WireShift',
      WireShiftA: {
        name: 'name',
        dataTime: 'Data time',
        tenminuteaveragewindspeed: '10 minute average wind speed',
        tenminuteaveragewinddirection: '10 minute average wind direction',
        MaximumOrMaximumOrStandardWindSpeed: 'Maximum/Maximum/Standard Wind Speed',
        airtemperature: 'Air temperature',
        humidity: 'humidity',
        pressure: 'pressure',
        rainfall: 'rainfall',
        Precipitationintensity: 'Precipitation intensity',
        Lightradiationintensity: 'Light radiation intensity',
        Icethickness: 'Ice thickness',
        Danceamplitude: 'Dance amplitude',
        Dancefrequency: 'Dance frequency',
        line: 'line',
        tower: 'tower',
        device: 'device',
        time: 'time',
        query: 'query',
        loadingData: 'Obtaining data, please be patient and wait...',
        reload: 'reload',
        Camerapoweredon: 'Camera powered on',
        Radarpoweron: 'Radar power on',
        Radarpoweroutage: 'Radar power outage',
        CloseVideo: 'Close Video',
        OpenVideo: 'Open Video',
        count: 'count',
        data: 'data'
      }
    }
  },
  process: 'process',
  processA: {
    DailyInspection: 'Daily inspection',
    DailyInspectionA: {
      InspectionFormNumber: 'Inspection For mNumber',
      Inspectiontime: 'Inspection time',
      Inspector: 'Inspector',
      Inspectionroute: 'Inspection route',
      Inspectionrecords: 'Inspection records',
      operate: 'operate',
      oddnumbers: 'Odd numbers',
      time: 'time',
      line: 'line',
      query: 'query',
      add: 'add',
      view: 'view',
      delete: 'delete',
      picture: 'picture',
      Inspectioninformation: 'Inspection information',
      Inspectionpictures: 'Inspection pictures',
      Remark: 'Remark',
      Remarksfuzzyquery: 'Remarks fuzzy query',
      pictureinformation: 'Picture information',
      submitto: 'Submit to'
    },
    HiddenDangerManage: 'Hiddendanger manage',
    HiddenDangerManageA: {
      Hazarddescription: 'Hazard description',
      time: 'time',
      Hazardlevel: 'Hazard level',
      Hazardtype: 'Hazard type',
      HazardSources: 'Hazard sources',
      operate: 'operate',
      Handlingsituation: 'Handling situation',
      query: 'query',
      add: 'add',
      view: 'view',
      eliminate: 'eliminate',
      Patrolinspection: 'Patrolinspection',
      HazardRecords: 'Hazard records',
      Inspectioninformation: 'Inspection information'
    }
  },
  state: 'state',
  stateA: {
    CableTunnel: 'CableTunnel',
    CableTunnelA: {
      Fanwaterpump: 'Fan waterpump',
      FanwaterpumpA: {
        name: 'name',
        deviceType: 'deviceType',
        stateTime: 'stateTime',
        workState: 'workState',
        Connectionstatus: 'Connection status',
        Workingmode: 'Working mode',
        Workingstatechangemode: 'Working state change mode',
        fan: 'fan',
        waterpump: 'waterpump',
        open: 'open',
        close: 'close',
        connect: 'connect',
        break: 'break',
        LocalOrRemoteManual: 'Local/Remote Manual',
        Localautomatic: 'Local automatic',
        Edgecomputinglinkage: 'Edge computing linkage',
        Ordinaryevents: 'Ordinary events',
        Timedventilationevent: 'Timed ventilation event',
        Linkageevents: 'Linkage events',
        query: 'query',
        time: 'time',
        export: 'export',
        data: 'data',
        loadingData: 'Obtaining data, please be patient and wait...'
      },
      Devicestatus: 'Device status',
      DevicestatusA: {
        name: 'name',
        Deliverytime: 'Delivery time',
        workingvoltage: 'workingvoltage',
        operationtemperature: 'Operation temperature',
        Charginganddischargingstatus: 'Charging and discharging status',
        Totalworkinghours: 'Total workinghours',
        Workinghours: 'Working hours',
        Connectionstatus: 'Connection status',
        Powersupplyvoltage: 'Power supply voltage',
        Carryingvoltage: 'Carrying voltage',
        longitude: 'longitude',
        latitude: 'latitude',
        Sensorstatus: 'Sensorstatus',
        time: 'time',
        query: 'query',
        charge: 'charge',
        discharge: 'discharge',
        Connectionisnormal: 'Connection is normal',
        connectionexception: 'Connection exception',
        export: 'export',
        count: 'count',
        data: 'data',
        loadingData: 'Obtaining data, please be patient and wait...',
        HistoryData: 'HistoryData'
      },
      Emergencycommunication: 'Emergency communication',
      EmergencycommunicationA: {
        name: 'name',
        dataTime: 'Data time',
        dataType: 'dataType',
        deviceState: 'deviceState',
        operate: 'operate',
        tunnel: 'tunnel',
        firecompartment: 'Fire compartment',
        device: 'device',
        state: 'state',
        time: 'time',
        query: 'query',
        export: 'export',
        timingdata: 'Timing data',
        statechange: 'State change',
        Callingfortesting: 'Calling for testing',
        loadingData: 'Obtaining data, please be patient and wait...',
        allExport: 'allExport'
      }
    }
  },
  maintenance: 'maintenance',
  maintenanceA: {
    Ledgermaintenance: 'Ledger maintenance',
    Usermanagement: 'User management',
    UsermanagementA: {
      Userinformation: 'User information',
      Modifyavatar: 'Modify avatar',
      Username: 'User name',
      Name: 'Name',
      Itemgrouping: 'Item grouping',
      Profession: 'Profession',
      Upperleveluser: 'Upper level user',
      Mobilephonenumber: 'Mobile phone number',
      Wechatgroup: 'Wechat group',
      Remark: 'Remark',
      Usemark: 'Use mark',
      Operate: 'Operate',
      add: 'Add',
      see: 'See',
      delete: 'Delete',
      route: 'Route',
      power: 'Power',
      product: 'Product',
      password: 'password',
      Passwordconfirmation: 'Password confirmation',
      notes: 'notes',
      Electricitymajor: 'Electricity major',
      resetting: 'resetting',
      submit: 'submit',
      name: 'name',
      query: 'query',
      renew: 'renew',
      code: 'code',
      type: 'type',
      company: 'company',
      position: 'position',
      producttype: 'Product type',
      Affiliatedcompany: 'Affiliated company',
      instruct: 'instruct',
      Enable: 'Enable',
      Deactivate: 'Deactivate',
      routeID: 'code',
      routeGroup: 'group',
      routeDirect: 'direct',
      routeName: 'name',
      routeIcon: 'icon',
      routeSort: 'sort'
    },
    Systemconfiguration: 'System configuration',
    SystemconfigurationA: {
      name: 'name',
      query: 'query',
      add: 'add',
      code: 'code',
      notes: 'notes',
      Operate: 'Operate',
      see: 'see',
      delete: 'delete',
      SystemRouting: 'System routing',
      resetting: 'resetting',
      submit: 'submit'
    },
    Devicetree: 'Device tree',
    Monitoringtype: 'Monitoring type',
    Datatablemanagement: 'Data table management',
    Producttype: 'Product type',
    Alarmlevel: 'Alarm level',
    Alarmtype: 'Alarm type',
    Imagerecognitionalarmtype: 'Image recognition alarm type',
    Thresholdtype: 'Threshold type',
    Communicationtype: 'Communication type',
    Nodetype: 'Node type',
    Powersupplytype: 'Power supply type',
    Fanpumpinformation: 'Fan pump information',
    Instructiontypeinformation: 'Instruction type information',
    Hiddendangertype: 'Hidden danger type',
    Sourceofhiddendanger: 'Source of hidden danger',
    Hiddendangerlevel: 'Hidden danger level',
    Manholecoverincident: 'Manhole cover incident',
    Alarmaudiotype: 'Alarm audio type',
    Inspectionobject: 'Inspection object',
    Parameterconfiguration: 'Parameter configuration',
    Electricpowermajor: 'Electric power major',
    Companymaintenance: 'Company maintenance',
    Corporaterelationship: 'Corporate relationship',
    Organizationmanagement: 'Organization management'
  }
}
