module.exports = {
  first: '首页总览',
  firstA: {
    DisplaySettings: '显示设置',
    monitorDeviceTotal: '已装设备总数',
    Unclosedhazards: '未关闭隐患',
    UnhandleAlarms: '未处理告警数量',
    offlineDevice: '离线设备数量',
    Numberofalarmdevicestoday: '今日告警设备数量',
    alarmprocessinginthepast7days: '近7日报警处理进度',
    alarmStatistics: '报警统计',
    deviceState: '设备状态',
    Alarmlevelproportion: '告警等级占比',
    AlarmTypeProportion: '告警类型占比',
    Alarmprocessingproportion: '告警处理占比',
    device: '设备',
    time: '时间',
    alarmLevel: '报警等级',
    alarmType: '报警类型',
    state: '状态'
  },
  alarm: '报警统计',
  alarmA: {
      name: '名称',
      time: '时间',
      alarmLevel: '报警等级',
      alarmType: '报警类型',
      alarmContent: '报警内容',
      alarmDetail: '报警详情',
      handle: '处理',
      Operate: '操作',
      query: '查询',
      export: '导出',
      batchHandel: '批处理',
      detail: '详情',
      alarmHistory: '报警历史',
      unhandle: '未处理',
      processed: '已处理'
  },
  monitor: '监测数据',
  monitorA: {
    tunnel: '隧道本体',
    tunnelA: {
      OpticalFiberThermometry: '光纤测温',
      OpticalFiberThermometryA: {
        Channel: '通道',
        Datatime: '数据时间',
        Maximumtemperature: '最高温度',
        Minimumtemperature: '最低温度',
        Averagetemperature: '平均温度',
        Carryingcapacity: '载流量',
        Fiberbreakingposition: '断纤位置',
        Numberofalarmpoints: '报警点数量',
        Maximumtemperaturealarm: '温度最大报警',
        Currentcarryingalarm: '载流量报警',
        Operate: '操作',
        tunnel: '隧道',
        line: '线路',
        device: '设备',
        time: '时间',
        query: '查询',
        export: '导出',
        partition: '分区',
        Fireproofpartition: '防火分区',
        fan: '风机',
        Waterpump: '水泵',
        Callingfortesting: '召测',
        state: '状态',
        control: '控制',
        loadingData: '正在获取数据，请耐心等待。。。'
      },
      CableLocalDischarge: '电缆局放',
      CableLocalDischargeA: {
        Name: '名称',
        Datatime: '数据时间',
        AorBorCPeakdischarge: 'A/B/C 峰值放电',
        AorBorCCumulativedischarge: 'A/B/C 累计放电',
        AorBorCDischargefrequency: 'A/B/C 放电频次',
        AorBorCAlarmlevel: 'A/B/C 报警等级',
        Operate: '操作'
      },
      SheathCurrent: '护层环流',
      SheathCurrentA: {
        Name: '名称',
        Datatime: '数据时间',
        Aphasecurrent: 'A相护层环流',
        Bphasecurrent: 'B相护层环流',
        Cphasecurrent: 'C相护层环流',
        Aphasejointtemperature: 'A相接头温度',
        Bphasejointtemperature: 'B相接头温度',
        Cphasejointtemperature: 'C相接头温度',
        tempN: '总接地温度',
        Runningcurrent: '运行电流',
        TotalgroundingCurrent: '总接地电流',
        Extremalratio: '极值比',
        Loadratio: '负荷比',
        CurrentTB: 'B相运行电流',
        CurrentTC: 'C相运行电流',
        Operate: '操作'
      },
      EnvironmentalMonitoring: '环境监测',
      EnvironmentalMonitoringA: {
        Name: '名称',
        Datatime: '数据时间',
        Otwoconcentration: 'O₂浓度',
        CHfourconcentration: 'CH₄浓度',
        HtwoSconcentration: 'H₂S浓度',
        COconcentration: 'CO浓度',
        Temperature: '温度',
        Humidness: '湿度',
        Operate: '操作'
      },
      WaterLevelMonitoring: '水位监测',
      WaterLevelMonitoringA: {
        Name: '名称',
        Datatime: '数据时间',
        Waterlevel: '水位',
        Operate: '操作'
      },
      ManholeCoverMonitoring: '井盖检测',
      ManholeCoverMonitoringA: {
        Name: '名称',
        Datatime: '数据时间',
        Eventtype: '事件类型',
        Openandclosedstate: '开闭状态',
        Intrusionstate: '入侵状态',
        Inclinedcondition: '倾斜状态',
        Vibrationalstate: '振动状态',
        Damagedcondition: '破损状态',
        Operate: '操作'
      }
    },
    Substation: '变电站',
    SubstationA: {
      SubstationGroundGrid: '变电接地网',
      SubstationGroundGridA: {
        query: '查询',
        add: '添加',
        detectionunit: '检测单元',
        StartTime: '开始时间',
        EndTime: '结束时间',
        Approver: '核准人',
        Checker: '审核人',
        Surveyor: '勘测人',
        DrawingNo: '图纸编号',
        version: '版本',
        Operate: '操作',
        enter: '录入',
        modify: '修改',
        detail: '详情',
        delete: '删除',
        StartingcoordinatesX: '起始坐标x',
        StartingcoordinatesY: '起始坐标y',
        TerminationcoordinatesX: '终止坐标x',
        TerminationcoordinatesY: '终止坐标y',
        fieldstrength: '场强',
        operate: '操作'
      }
    },
    overflow: '溢水',
    overflowA: {
      treeId: '名称',
      timeStamp: '采集时间',
      tempAir1: '温度1(℃)',
      humidity1: '湿度1(%RH)',
      tempAir2: '温度2(℃)',
      humidity2: '湿度2(%RH)',
      voltageOut: '输出电压(V)',
      currentOut: '输出电流(A)',
      voltageAc: '交流电压(V)',
      tempPfc: 'PFC温度(℃)',
      voltageOutDc: '直流输出电压(V)',
      currentLoad: '负载总电流(A)',
      currentBat: '电池电流(A)',
      tempBat: '电池温度(℃)',
      voltageBat: '电池电压(V)',
      powerSupply: '系统供电状态',
      alarmDesc: '报警描述',
      Operate: '操作'
    },
    Visualization: '可视化',
    VisualizationA: {
      RealVideo: '实时视频',
      InspectionRobot: '巡线机器人',
      threeDSecurityLaser: '立体安防激光',
      threeDSecurityPointCloud: '立体安防点云',
      InspectionRobotA: {
        batchOperation: '批量操作',
        query: '查询',
        batchWakeUp: '批量唤醒',
        batchSleep: '批量休眠',
        wakeUp: '唤醒',
        sleep: '休眠',
        code: '编码',
        name: '名称',
        issued: '发送',
        response: '响应',
        Operate: '操作',
        loadingData: '正在获取数据，请耐心等待。。。',
        total: '共计',
        number: '设备'
      }
    },
    TransmitElectricity: '输电专业',
    TransmitElectricityA: {
      TowerTilt: '杆塔倾斜',
      TowerTiltA: {
          name: '名称',
          datetime: '数据时间',
          inclination: '倾斜度',
          Inclinationalongline: '顺线倾斜度',
          Lateralinclination: '横向倾斜度',
          Alonglineinclinationangle: '顺线倾斜角',
          Lateralinclinationangle: '横向倾斜角',
          Eastwarddisplacement: '东向位移',
          Northbounddisplacement: '北向位移',
          verticaldisplacement: '垂直位移',
          operate: '操作',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          allExport: '全部导出',
          Callingfortesting: '召测',
          count: '统计',
          data: '数据',
          loadingData: '正在获取数据，请耐心等待。。。'
      },
      Transmissionmeteorology: '输电气象',
      TransmissionmeteorologyA: {
          name: '名称',
          dataTime: '数据时间',
          averagewindspeed: '平均风速',
          Averagewinddirection: '平均风向',
          maximumwindspeed: '最大风速',
          extremewindspeed: '极大风速',
          Standardizedwindspeed: '标准风速',
          airtemperature: '气温',
          humidity: '湿度',
          pressure: '气压',
          rainfall: '降雨量',
          Precipitationintensity: '降水强度',
          Lightradiationintensity: '光辐射强度',
          operate: '操作',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          Callingfortesting: '召测',
          loadingData: '正在获取数据，请耐心等待。。。',
          count: '统计',
          data: '数据',
          allexport: '全部导出'
      },
      WireTemperatureMeasurement: '导线测温',
      WireTemperatureMeasurementA: {
          name: '名称',
          DataStart: '数据起始',
          DataEnd: '数据结束',
          gathertotal: '采集总数',
          maximumtemperature: '最高温度',
          minimumtemperature: '最低温度',
          Numberofcollected0values: '采集0值数量',
          Collectionofcollectionpoints: '采集点集合',
          operate: '操作',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          Callingfortesting: '召测',
          loadingData: '正在获取数据，请耐心等待。。。',
          count: '统计',
          data: '数据',
          allexport: '全部导出'
      },
      TowerStress: '杆塔应力',
      TowerStressA: {
          name: '名称',
          dataTime: '数据时间',
          gathertotal: '采集总数',
          Maximumvalue: '最大值',
          minimumvalue: '最小值',
          averagevalue: '平均值',
          Collectionofcollectionpoints: '采集点集合',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          allExport: '全部导出',
          loadingData: '正在获取数据，请耐心等待。。。',
          count: '统计',
          data: '数据'
      },
      BoltTighteningForce: '螺栓拧紧力',
      BoltTighteningForceA: {
        name: '名称',
        dataTime: '数据时间',
        gathertotal: '采集总数',
        Maximumvalue: '最大值',
        minimumvalue: '最小值',
        averagevalue: '平均值',
        Collectionofcollectionpoints: '采集点集合',
        line: '线路',
        tower: '杆塔',
        device: '设备',
        time: '时间',
        query: '查询',
        export: '导出',
        allExport: '全部导出',
        loadingData: '正在获取数据，请耐心等待。。。',
        count: '统计',
        data: '数据'
      },
      WireDance: '导线舞动',
      WireDanceA: {
          name: '名称',
          dataTime: '数据时间',
          Totalnumberofcollectionunits: '采集单元总数',
          Collectionunitserialnumber: '采集单元序号',
          Danceamplitude: '舞动幅值',
          Verticaldanceamplitude: '垂直舞动幅值',
          Horizontaldanceamplitude: '水平舞动幅值',
          Dancingellipseinclinationangle: '舞动椭圆倾斜角',
          Dancefrequency: '舞动频率',
          operate: '操作',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          loadingData: '正在获取数据，请耐心等待。。。',
          count: '统计',
          Callingfortesting: '召测',
          Featurequantityexport: '特征量导出',
          Trackexport: '轨迹导出',
          feature: '特征量',
          trajectory: '轨迹',
          offset: '偏移量'
      },
      IcingTension: '覆冰张力',
      IcingTensionA: {
          name: '名称',
          dataTime: '数据时间',
          Icethickness: '覆冰厚度',
          Suspendedload: '悬挂载荷',
          tensiondifference: '张力差',
          Numberofsensors: '传感器个数',
          Tensilevalue: '拉力值',
          Winddeflectionangle: '风偏角',
          offsetangle: '偏斜角',
          dataset: '数据集',
          operate: '操作',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          allExport: '全部导出',
          loadingData: '正在获取数据，请耐心等待。。。',
          Callingfortesting: '召测',
          count: '统计',
          data: '数据'
      },
      GroundingElectrodeMonitoring: '接地极检测',
      GroundingElectrodeMonitoringA: {
          name: '名称',
          dataTime: '数据时间',
          Groundingresistance: '接地电阻',
          batteryvoltage: '电池电压',
          Signalstrength: '信号强度',
          Numberoflightningstrikes: '雷击次数',
          Recentlightningstriketime: '最近雷击时间',
          line: '线路',
          tower: '杆塔',
          device: '设备',
          time: '时间',
          query: '查询',
          export: '导出',
          loadingData: '正在获取数据，请耐心等待。。。',
          Signalstrength1: '信号强度1',
          Signalstrength2: '信号强度2',
          LeakagecurrentA: '泄露电流A',
          LeakagecurrentB: '泄露电流B',
          LeakagecurrentC: '泄露电流C',
          NumberoflightningstrikesNo: '第几次雷击',
          NumberoflightningstrikesA: '雷击次数A',
          NumberoflightningstrikesB: '雷击次数B',
          NumberoflightningstrikesC: '雷击次数C',
          towergroundingresistance: '杆塔接地电阻',
          Towergroundingcurrent: '杆塔接地电流',
          Apolegroundingresistance: 'A极接地电阻',
          Bpolegroundingresistance: 'B极接地电阻',
          Cpolegroundingresistance: 'C极接地电阻',
          Dpolegroundingresistance: 'D极接地电阻',
          Apolegroundingcurrent: 'A极接地电流',
          Bpolegroundingcurrent: 'B极接地电流',
          Cpolegroundingcurrent: 'C极接地电流',
          Dpolegroundingcurrent: 'D极接地电流',
          Apolegroundingimpulsecurrent: 'A对地瞬时冲击电流',
          Bpolegroundingimpulsecurrent: 'B对地瞬时冲击电流',
          Cpolegroundingimpulsecurrent: 'C对地瞬时冲击电流',
          Dpolegroundingimpulsecurrent: 'D对地瞬时冲击电流',
          allExport: '全部导出',
          count: '统计',
          data: '数据',
          temp: '雷击统计'
      },
      WireShift: '导线位移',
      WireShiftA: {
        name: '名称',
        dataTime: '数据时间',
        tenminuteaveragewindspeed: '10分钟平均风速',
        tenminuteaveragewinddirection: '10分钟平均风向',
        MaximumOrMaximumOrStandardWindSpeed: '最大/极大/标准风速',
        airtemperature: '气温',
        humidity: '湿度',
        pressure: '气压',
        rainfall: '降雨量',
        Precipitationintensity: '降水强度',
        Lightradiationintensity: '光辐射强度',
        Icethickness: '覆冰厚度',
        Danceamplitude: '舞动幅值',
        Dancefrequency: '舞动频率',
        line: '线路',
        tower: '杆塔',
        device: '设备',
        time: '时间',
        query: '查询',
        loadingData: '正在获取数据，请耐心等待。。。',
        reload: '刷新',
        Camerapoweredon: '摄像机上电',
        Radarpoweron: '雷达上电',
        Radarpoweroutage: '雷达断电',
        CloseVideo: '关闭视频',
        OpenVideo: '打开视频',
        count: '统计',
        data: '数据'
      }
    }
  },
  process: '流程管理',
  processA: {
    DailyInspection: '日常巡检',
    DailyInspectionA: {
      InspectionFormNumber: '巡检单号',
      Inspectiontime: '巡检时间',
      Inspector: '巡检人',
      Inspectionroute: '巡检线路',
      Inspectionrecords: '巡检记录',
      operate: '操作',
      oddnumbers: '单号',
      time: '时间',
      line: '线路',
      query: '查询',
      add: '添加',
      view: '查看',
      delete: '删除',
      picture: '图片',
      Inspectioninformation: '巡检信息',
      Inspectionpictures: '巡检图片',
      Remark: '备注信息',
      Remarksfuzzyquery: '备注模糊查询',
      pictureinformation: '图片信息',
      submitto: '提交'
    },
    HiddenDangerManage: '隐患管理',
    HiddenDangerManageA: {
      Hazarddescription: '隐患描述',
      time: '时间',
      Hazardlevel: '隐患等级',
      Hazardtype: '隐患类型',
      HazardSources: '隐患来源',
      operate: '操作',
      Handlingsituation: '处理情况',
      query: '查询',
      add: '添加',
      view: '查看',
      eliminate: '消除',
      Patrolinspection: '巡检',
      HazardRecords: '隐患记录',
      Inspectioninformation: '巡检信息'
    }
  },
  state: '状态',
  stateA: {
    CableTunnel: '电缆隧道',
    CableTunnelA: {
      Fanwaterpump: '风机水泵',
      FanwaterpumpA: {
          name: '名称',
          deviceType: '设备类型',
          stateTime: '状态时间',
          workState: '工作状态',
          Connectionstatus: '连接状态',
          Workingmode: '工作模式',
          Workingstatechangemode: '工作状态变换模式',
          fan: '风机',
          waterpump: '水泵',
          open: '开',
          close: '关',
          connect: '连接',
          break: '断开',
          LocalOrRemoteManual: '本地/远程手动',
          Localautomatic: '本地自动',
          Edgecomputinglinkage: '边缘计算联动',
          Ordinaryevents: '普通事件',
          Timedventilationevent: '定时通风事件',
          Linkageevents: '联动事件',
          query: '查询',
          time: '时间',
          export: '导出',
          data: '数据',
          loadingData: '正在获取数据，请耐心等待。。。'
      },
      Devicestatus: '设备状态',
      DevicestatusA: {
          name: '名称',
          Deliverytime: '上送时间',
          workingvoltage: '工作电压',
          operationtemperature: '工作温度',
          Charginganddischargingstatus: '充放电状态',
          Totalworkinghours: '工作总时长',
          Workinghours: '工作时长',
          Connectionstatus: '连接状态',
          Powersupplyvoltage: '取电电压',
          Carryingvoltage: '承载电压',
          longitude: '经度',
          latitude: '纬度',
          Sensorstatus: '传感器状态',
          time: '时间',
          query: '查询',
          charge: '充电',
          discharge: '放电',
          Connectionisnormal: '连接正常',
          connectionexception: '连接异常',
          export: '导出',
          count: '统计',
          data: '数据',
          loadingData: '正在获取数据，请耐心等待。。。',
          HistoryData: '历史数据'
      },
      Emergencycommunication: '应急通讯',
      EmergencycommunicationA: {
        name: '名称',
        dataTime: '数据时间',
        dataType: '数据类型',
        deviceState: '设备状态',
        operate: '操作',
        tunnel: '隧道',
        firecompartment: '防火分区',
        device: '设备',
        state: '状态',
        time: '时间',
        query: '查询',
        export: '导出',
        timingdata: '定时数据',
        statechange: '状态改变',
        Callingfortesting: '召测',
        loadingData: '正在获取数据，请耐心等待。。。',
        allExport: '全部导出'
      }
    }
  },
  maintenance: '维护',
  maintenanceA: {
    Ledgermaintenance: '台账维护',
    Usermanagement: '用户管理',
    UsermanagementA: {
      Userinformation: '用户信息',
      Modifyavatar: '修改头像',
      Username: '用户名',
      Name: '姓名',
      Itemgrouping: '项目分组',
      Profession: '专业',
      Upperleveluser: '上级用户',
      Mobilephonenumber: '手机号码',
      Wechatgroup: '微信分组',
      Remark: '备注',
      Usemark: '使用标记',
      Operate: '操作',
      add: '添加',
      see: '查看',
      delete: '删除',
      route: '路由',
      power: '权限',
      product: '产品',
      password: '密码',
      Passwordconfirmation: '密码确认',
      notes: '备注',
      Electricitymajor: '电力专业',
      resetting: '重置',
      submit: '提交',
      name: '名称',
      query: '查询',
      renew: '更新',
      code: '编码',
      type: '类型',
      company: '公司',
      position: '位置',
      producttype: '产品类型',
      Affiliatedcompany: '所属公司',
      instruct: '指令',
      Enable: '启用',
      Deactivate: '停用',
      routeID: '路由编码',
      routeGroup: '路由分组',
      routeDirect: '路由跳转ID',
      routeName: '路由名称',
      routeIcon: '路由图标',
      routeSort: '排序'
    },
    Systemconfiguration: '系统配置',
    SystemconfigurationA: {
        name: '名称',
        query: '查询',
        add: '添加',
        code: '编码',
        notes: '备注',
        Operate: '操作',
        see: '查看',
        delete: '删除',
        SystemRouting: '系统路由',
        resetting: '重置',
        submit: '提交'
    },
    Devicetree: '设备树',
    Monitoringtype: '监测类型',
    Datatablemanagement: '数据表管理',
    Producttype: '产品类型',
    Alarmlevel: '报警等级',
    Alarmtype: '报警类型',
    Imagerecognitionalarmtype: '图像识别报警类型',
    Thresholdtype: '阈值类型',
    Communicationtype: '通讯类型',
    Nodetype: '节点类型',
    Powersupplytype: '供电类型',
    Fanpumpinformation: '风机水泵信息',
    Instructiontypeinformation: '指令类型信息',
    Hiddendangertype: '隐患类型',
    Sourceofhiddendanger: '隐患来源',
    Hiddendangerlevel: '隐患等级',
    Manholecoverincident: '井盖事件',
    Alarmaudiotype: '告警音频类型',
    Inspectionobject: '巡检目标',
    Parameterconfiguration: '参数配置',
    Electricpowermajor: '电力专业',
    Companymaintenance: '公司维护',
    Corporaterelationship: '公司关系',
    Organizationmanagement: '组织管理'
  }
}
